<script>
  import { page, inertia } from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Range from "@/Components/Forms/Range.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconTick} from "@/Components/Assets/Icons/index.js";

  export let form;

  export let callback = () => {};
</script>

<div class="pitch">
  <div class="head">
    <div>
      <div class="step">
        <Icon data={IconTick} size="lg" class="fill-white" />
      </div>
      <p>Write your pitch</p>
    </div>

    <span class="ellipsis"></span>

    <div>
      <div class="step active">2</div>
      <p>Make an offer</p>
    </div>

    <span class="ellipsis"></span>
    <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
  </div>

  <form method="post" on:submit|preventDefault={(event) => callback(event)}>
    <div class="field">
      <Range
        valuePosition="!text-red-400 !text-4xl mb-4"
        inputClass="!accent-red-400"
        class="w-full lg:w-full"
        name="request_fee"
        minLabel={`$${$page.props?.profile?.pitch_price ?? 25}`}
        min={$page.props?.profile?.pitch_price ?? 25}
        maxLabel="$2000"
        max="2000"
        valuePrefix="$"
        bind:value={$form.request_fee}
      />
      <p>
        You will only be charged if I review your pitch and respond in writing.
      </p>
      {#if $form.errors.request_fee}
        <div class="!mt-1 text-red-600 text-sm">
          {$form.errors.request_fee}
        </div>
      {/if}
    </div>

    <div class="actions">
      <Button
        dynamic
        color="tertiary"
        size="sm"
        href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
      >Back</Button>

      <Button type="submit" class="!px-8" color="red" size="sm" on:click={(event) => callback(event)}>Next</Button>
    </div>
  </form>

  <div class="step-indicator">
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
       class="dot" use:inertia={{preserveScroll: true}}
    >
    </a>
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
       class="dot active" use:inertia={{preserveScroll: true}}
    >
    </a>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</div>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;

        &.active {
          @apply bg-red-500;
        }
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }
  }

  form {
    @apply w-9/12 mx-auto;
  }

  .field {
    @apply bg-white rounded-lg p-6;

    p {
      @apply text-grey-600 mt-5 text-sm;
    }
  }

  .actions {
    @apply flex justify-between items-center mt-4;
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
