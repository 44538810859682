<script>
  import Layout from '@/Layouts/Auth.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, useForm} from '@inertiajs/svelte'
  import Label from "@/Components/Forms/Label.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import cn from "classnames";
  import Turnstile from "@/Components/Forms/Turnstile.svelte";

  let form = useForm({
    email: $page.props?.email ?? '',
    cf_response: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    $form.post('/auth/login');
  };
</script>

<Seo title="Login" />

<Layout title="Login">
  {#if $page.props.auth.loggedIn}
    <Profile
      image={$page.props.auth?.user?.avatar}
      imageAlt={$page.props.auth?.user?.name}
      subImage={$page.props.auth?.org?.logo}
      subImageAlt={$page.props.auth?.org?.name}
      title={$page.props.auth?.user?.name}
      subTitle={$page.props.auth?.user?.email}
      href="/app/respond"
    />

    <div class="buttons">
      <Button color="tertiary" class="!text-sm" href="/app/respond">Continue</Button>
      <Button color="tertiary" class="!text-sm" href="/logout">Log out</Button>
    </div>
  {:else}
    <form on:submit|preventDefault={() => handleSubmit()}>
      <div>
        <Label name="email" label="Email" />

        <Input
          inputClass={cn($form.errors.email ? 'error' : '', '!bg-white !text-sm !rounded min-h-10')}
          id="email"
          type="email"
          name="email"
          bind:value={$form.email}
          autocomplete="email"
          placeholder="Enter your email address"
          loading={$form.processing}
          bind:error={$form.errors.email}
          autofocus
          required
        />
      </div>

      {#if import.meta.env.VITE_TURNSTILE_SITE_KEY}
        <Turnstile action="login" bind:response={$form.cf_response} bind:error={$form.errors.cf_response} />
      {/if}

      <div class="flex items-center justify-between mt-4">
        <Button
          type="submit"
          size="sm"
          disabled={$form.processing || (import.meta.env.VITE_TURNSTILE_SITE_KEY && $form.cf_response === '') || $form.email === ''}
          on:click={(e) => handleSubmit(e)}>Continue with Email</Button>
      </div>
    </form>
  {/if}
</Layout>

<style lang="postcss">
  form {
    @apply md:max-w-md;
  }

  .buttons {
    @apply mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2;
  }
</style>
