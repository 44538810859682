<script>
  import { page, useForm, router, inertia } from "@inertiajs/svelte";
  import { onMount } from "svelte";
  import Layout from "@/Layouts/User.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Range from "@/Components/Forms/Range.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconTick} from "@/Components/Assets/Icons/index.js";
  import posthog from "posthog-js";

  let storage = $page.props.profile?.source === '5x5' ? '_bq' : '';

  let form = useForm({
    request_fee: $page.props?.profile.pitch_price ?? 25,
    type : $page.props?.profile?.source === '5x5' ? 'profiles' : 'users'
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if ($page.props?.auth?.loggedIn) {
      $form.post("/pitch");
    } else {
      if (!$form.request_fee || $form.request_fee === "") {
        $form.errors.request_fee = "Please set an amount for your pitch";
        return;
      }

      if ($page.props?.profile.pitch_price && $form.request_fee < $page.props?.profile.pitch_price) {
        $form.errors.request_fee = "Your pitch price should be greater than or equal to $" + $page.props?.profile.pitch_price;
        return;
      }

      if ($form.request_fee > 2000) {
        $form.errors.request_fee = "The maximum pitch price is $2000";
        return;
      }

      let pitchFormData;
      let pitchData = localStorage.getItem(`pitch_data${storage}_${$page.props.profile?.id }`);
      if (pitchData) {
        pitchFormData = JSON.parse(pitchData);

        if (!pitchFormData.body || pitchFormData.body === "") {
          $form.errors.request_fee = "Unable to find your pitch, please go back and write your pitch again.";
          return;
        }

        pitchFormData.request_fee = $form?.request_fee;
        localStorage.setItem(`pitch_data${storage}_${$page.props.profile?.id }`, JSON.stringify(pitchFormData));

        if (posthog && posthog.__loaded) {
          let payload = {
            body: pitchFormData.body,
            amount: $form.request_fee,
            buyer_id: $page.props.profile?.id,
            buyer_email: $page.props.profile?.email,
            buyer_name: $page.props.profile?.name,
            buyer_slug: $page.props.profile?.slug,
            status: 'Draft'
          };

          if ($page.props?.auth?.loggedIn) {
            if (!posthog._isIdentified()) {
              posthog.identify($page.props.auth.user.id, {
                email: $page.prop?.auth?.user?.email,
                name: $page.prop?.auth?.user?.name,
              });
            }

            payload.seller_id = $page.props.auth.user.id;
            payload.seller_email = $page.props.auth.user.email;
            payload.seller_name = $page.props.auth.user.name;
            payload.seller_slug = $page.props.auth.user.slug;
          }

          posthog.capture("Pitch rate set", payload);
        }

        router.visit("/@" + $page.props?.profile?.slug + "/login", {
          preserveState: true,
          preserveScroll: true
        });
      } else {
        router.visit("/@" + $page.props?.profile?.slug);
      }
    }
  };

  onMount(async () => {
    let pitchFormData = localStorage.getItem(`pitch_data_${$page.props?.profile?.id }`);
    if (pitchFormData) {
      pitchFormData = JSON.parse(pitchFormData);
      if (pitchFormData?.request_fee) {
        $form.request_fee = pitchFormData?.request_fee;
      }
    } else {
      router.visit("/@" + $page.props?.profile?.slug);
    }
  });
</script>

<Layout profile={$page.props?.profile}>
  <Seo
    title={`Set rate for ${$page.props?.profile?.name}`}
    breadcrumbs={[
      { name: 'Marketplace', slug: '/marketplace' },
      { name: $page.props?.profile?.name, slug: `/@${$page.props?.profile?.slug}` },
      { name: `Set rate for ${$page.props?.profile?.name}` },
    ]}
    metaDescription={$page.props?.profile?.about ? $page.props?.profile?.about : `Set rate for ${$page.props?.profile?.name}`}
    ogImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
    twitterImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
  />

  <div class="pitch">
    <div class="head">
      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Write your pitch</p>
      </div>

      <span class="ellipsis"></span>

      <div>
        <div class="step active">2</div>
        <p>Make an offer</p>
      </div>

      <span class="ellipsis"></span>
      <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
    </div>

    <form method="post" on:submit|preventDefault={(event) => handleSubmit(event)}>
      <div class="field">
        <Range
          valuePosition="!text-red-400 !text-4xl mb-4"
          inputClass="!accent-red-400"
          class="w-full lg:w-full"
          name="request_fee"
          minLabel={`$${$page.props?.profile?.pitch_price ?? 25}`}
          min={$page.props?.profile?.pitch_price ?? 25}
          maxLabel="$2000"
          max="2000"
          valuePrefix="$"
          bind:value={$form.request_fee}
        />
        <p>
          You will only be charged if I review your pitch and respond in writing.
        </p>
        {#if $form.errors.request_fee}
          <div class="!mt-1 text-red-600 text-sm">
            {$form.errors.request_fee}
          </div>
        {/if}
      </div>

      <div class="actions">
        <a class="btn-white" href={`/@${$page.props?.profile?.slug}/pitch`} use:inertia={{preserveScroll: true, preserveState: true}}>Back</a>
        <Button type="submit" class="!px-8" color="red" size="sm" on:click={(event) => handleSubmit(event)}>Next</Button>
      </div>
    </form>

    <div class="step-indicator">
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
         class="dot active" use:inertia={{preserveScroll: true}}
      >
      </a>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;

        &.active {
          @apply bg-red-500;
        }
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }
  }

  form {
    @apply w-9/12 mx-auto;
  }

  .field {
    @apply bg-white rounded-lg p-6;

    p {
      @apply text-grey-600 mt-5 text-sm;
    }
  }

  .actions {
    @apply flex justify-between items-center mt-4;
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
